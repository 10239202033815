import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Container from '../views/components/Container';
import FormIntro from '../views/components/FormContact/FormIntro';
import FormContact from '../views/components/FormContact/index';
import SEO from '../views/components/SEO';
import Hero from '../views/layouts/Hero';
import MainLayout from '../views/layouts/MainLayout';

const ContactPage = ({
    data,
    location,
    pageContext: { locale, translations },
}) => {
    const { apiUrl, reCaptchaKey } = data.site.siteMetadata;
    return (
        <MainLayout
            locale={locale}
            translationsPaths={translations}
            neverTransparent
        >
            <SEO
                title='pages.contact.headerTitle'
                description='pages.contact.description'
                location={location}
                translationsPaths={translations}
            />
            <Hero
                image={data.hero.childImageSharp.gatsbyImageData}
                title='pages.contact.hero.h1'
                colors={data.hero.fields.colors}
                text1='pages.contact.hero.p1'
                textColorDark
                titleColorDark
            />
            <main id='content' className='gap'>
                <Container>
                    <FormIntro />
                    <FormContact apiUrl={apiUrl} reCaptchaKey={reCaptchaKey} />
                </Container>
            </main>
        </MainLayout>
    );
};

ContactPage.propTypes = {
    data: PropTypes.shape({
        hero: PropTypes.object.isRequired,
        site: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
    pageContext: PropTypes.shape({
        locale: PropTypes.string.isRequired,
        translations: PropTypes.array.isRequired,
    }).isRequired,
};

export default ContactPage;

export const pageQuery = graphql`
    query {
        hero: file(
            sourceInstanceName: { eq: "hero" }
            relativePath: { eq: "contact.jpg" }
        ) {
            ...BgImages
        }
        site {
            siteMetadata {
                apiUrl
                reCaptchaKey
            }
        }
    }
`;
