/* eslint-disable jsx-a11y/label-has-associated-control */
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import isEmail from 'validator/es/lib/isEmail';
import isNumeric from 'validator/es/lib/isNumeric';

import './FormContact.styl';

const FormContact = ({ apiUrl, intl: { formatMessage }, reCaptchaKey }) => {
    const { errors, handleSubmit, register } = useForm();
    const [mailSent, setMailSent] = useState({ error: false, sent: false });

    const onSubmit = (data) => {
        axios({
            data,
            headers: {
                'content-type': 'application/json',
            },
            method: 'post',
            url: apiUrl,
        })
            .then(() => {
                setMailSent({ error: false, sent: true });
            })
            .catch(() => {
                setMailSent({ error: true, sent: false });
            });
    };

    const onCaptchaChange = (token) => {
        console.log('FormContact -> token', token);
        // setCaptchaValue(token);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormattedMessage
                id='pages.contact.h3'
                tagName='h3'
                reCaptchaKey={reCaptchaKey}
            />
            <div className='input-bloc'>
                <label htmlFor='email'>Email</label>
                <input
                    name='email'
                    id='email'
                    ref={register({
                        required: 'errors.emptyField',
                        validate: {
                            format: (value) =>
                                isEmail(value) || 'errors.invalidEmail',
                        },
                    })}
                    placeholder={formatMessage({
                        id: 'pages.contact.form.email.placeholder',
                    })}
                />
                <div className='error-bloc'>
                    {errors.email && (
                        <FormattedMessage
                            id={errors.email.message}
                            values={{ field: 'email' }}
                        />
                    )}
                </div>
            </div>
            <div className='input-bloc'>
                <label htmlFor='phone'>
                    {formatMessage({
                        id: 'pages.contact.form.phone.label',
                    })}
                </label>
                <input
                    name='phone'
                    id='phone'
                    ref={register({
                        required: 'errors.emptyField',
                        validate: {
                            format: (value) =>
                                isNumeric(value) || 'errors.invalidNumber',
                        },
                    })}
                    placeholder={formatMessage({
                        id: 'pages.contact.form.phone.placeholder',
                    })}
                />
                <div className='error-bloc'>
                    {errors.phone && (
                        <FormattedMessage
                            id={errors.phone.message}
                            values={{ field: 'phone' }}
                        />
                    )}
                </div>
            </div>
            <div className='input-bloc'>
                <label htmlFor='message'>Message</label>
                <textarea
                    ref={register({
                        required: 'errors.emptyField',
                    })}
                    name='message'
                    id='message'
                    cols='30'
                    rows='10'
                    placeholder={formatMessage({
                        id: 'pages.contact.form.message.placeholder',
                    })}
                />
                <div className='error-bloc'>
                    {errors.message && (
                        <FormattedMessage
                            id={errors.message.message}
                            values={{ field: 'message' }}
                        />
                    )}
                </div>
            </div>
            <GoogleReCaptcha onVerify={onCaptchaChange} />
            <button type='submit'>
                <FormattedMessage id='pages.contact.form.submit' />
            </button>
            {mailSent.sent ? (
                <FormattedMessage id='pages.contact.form.sent' />
            ) : null}
            {mailSent.error ? (
                <FormattedMessage id='pages.contact.form.notSent' />
            ) : null}
        </form>
    );
};

FormContact.propTypes = {
    apiUrl: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
    reCaptchaKey: PropTypes.string.isRequired,
};

export default injectIntl(FormContact);
