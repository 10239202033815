import { Box, ResponsiveContext } from 'grommet';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

const FormIntro = () => {
    const size = useContext(ResponsiveContext);
    const isSmall = size === 'small';
    return (
        <Box direction='row' wrap className='contact-blocs'>
            <Box basis={isSmall ? 'full' : '1/2'}>
                <FormattedMessage id='pages.contact.bloc1.h2' tagName='h2' />
                <FormattedMessage id='pages.contact.bloc1.p' tagName='p' />
                <FormattedMessage id='pages.contact.bloc1.p2' tagName='p' />
            </Box>
            <Box basis={isSmall ? 'full' : '1/2'}>
                <FormattedMessage id='pages.contact.bloc2.h2' tagName='h2' />
                <FormattedMessage id='pages.contact.bloc2.p' tagName='p' />
                <FormattedMessage id='pages.contact.bloc2.p2' tagName='p' />
            </Box>
            <Box basis={isSmall ? 'full' : '1/2'}>
                <FormattedMessage id='pages.contact.bloc3.h2' tagName='h2' />
                <FormattedMessage id='pages.contact.bloc3.p' tagName='p' />
                <FormattedMessage id='pages.contact.bloc3.p2' tagName='p' />
            </Box>
            <Box basis={isSmall ? 'full' : '1/2'}>
                <FormattedMessage id='pages.contact.bloc4.h2' tagName='h2' />
                <FormattedMessage id='pages.contact.bloc4.p' tagName='p' />
                <FormattedMessage id='pages.contact.bloc4.p2' tagName='p' />
            </Box>
        </Box>
    );
};

export default FormIntro;
